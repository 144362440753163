<script setup lang="ts">
import type { DraggedElementType } from './types';

const emit = defineEmits<{
  dragged: [];
  dropped: [];
}>();
const modelLeft = defineModel<number>('left', {
  default: 0,
});
const modelRight = defineModel<number>('right', {
  default: 100,
});

const isLeftDragging = ref(false);
const isRightDragging = ref(false);
const containerRef = shallowRef<HTMLDivElement>();
const draggedElement = shallowRef<DraggedElementType>('left');

const leftButtonPosition = computed(() => {
  const value = modelLeft.value > 100 ? 100 : modelLeft.value;
  return { left: `${value < 0 ? 0 : value}%` };
});
const rightButtonPosition = computed(() => {
  const value = modelRight.value > 100 ? 100 : modelRight.value;
  return { left: `${value < 0 ? 0 : value}%` };
});
const railStyle = computed(() => {
  const rightVal = modelRight.value > 100 ? 100 : modelRight.value;
  const leftVal = modelLeft.value > 100 ? 100 : modelLeft.value;
  const commonWidth = rightVal - leftVal;
  const width = commonWidth >= 100 ? 100 : commonWidth;
  const absWidth = Math.abs(width) >= 100 ? 100 : Math.abs(width);
  const left = (modelRight.value < modelLeft.value) ? modelRight.value : modelLeft.value;
  const calcLeft = left < 0 ? 0 : left;
  const calcWidth = width < 0 ? absWidth : width;
  return {
    left: `${calcLeft}%`,
    width: `${calcLeft >= 100 ? 0 : calcWidth}%`,
  };
});

watch([isLeftDragging, isRightDragging], (value) => {
  const dragged = value.find(item => item);
  if (dragged !== undefined && dragged) {
    emit('dragged');
  } else {
    emit('dropped');
  }
});

function resetDragging() {
  if (isLeftDragging.value || isRightDragging.value) {
    isLeftDragging.value = false;
    isRightDragging.value = false;
    emit('dropped');
    removeTouchEventsListeners();
  }
}

function setDragging(value: boolean, position: DraggedElementType) {
  draggedElement.value = position;
  switch (position) {
    case 'left':
      isLeftDragging.value = value;
      break;
    case 'right':
      isRightDragging.value = value;
      break;
  }
  if (value) {
    listenTouchEvents();
  } else {
    removeTouchEventsListeners();
  }
};

function moveHandleButton(e: Event) {
  const isMouseEvent = e instanceof MouseEvent;
  const containerEl = unref(containerRef);

  if (containerEl) {
    const clientX = isMouseEvent ? e.clientX : (e as any).touches[0].clientX;
    const value = getDragingValue(clientX);
    if (value !== undefined) {
      if (isLeftDragging.value) {
        if (value >= 0) {
          modelLeft.value = value;
        }
      }
      if (isRightDragging.value) {
        if (value >= 0) {
          modelRight.value = value;
        }
      }
    }
  }
}

function getDragingValue(clientX: number) {
  const containerEl = unref(containerRef);
  if (containerEl) {
    const targetRect = containerEl.getBoundingClientRect();
    const thumbX = clientX - targetRect.left;
    const left = Math.trunc(((thumbX - 6) / targetRect.width) * 100);
    if (left > 100) {
      return 100;
    }

    return left;
  }
}

function listenTouchEvents() {
  document.addEventListener('mousemove', moveHandleButton);
  document.addEventListener('touchmove', moveHandleButton, { passive: true });
  document.addEventListener('mouseup', resetDragging);
  document.addEventListener('touchend', resetDragging, { passive: true });
}

function removeTouchEventsListeners() {
  document.removeEventListener('mousemove', moveHandleButton);
  document.removeEventListener('touchmove', moveHandleButton);
  document.removeEventListener('mouseup', resetDragging);
  document.removeEventListener('touchend', resetDragging);
}

onUnmounted(() => {
  removeTouchEventsListeners();
});
</script>

<template>
  <div
    ref="containerRef"
    class="app-slider">
    <div
      class="app-slider__rail"
      :style="railStyle" />
    <div
      class="app-slider__handle-button"
      :class="{ dragged: draggedElement === 'left' }"
      :style="leftButtonPosition"
      @mousedown="setDragging(true, 'left')"
      @touchstart.passive="setDragging(true, 'left')" />
    <div
      class="app-slider__handle-button"
      :class="{ dragged: draggedElement === 'right' }"
      :style="rightButtonPosition"
      @mousedown="setDragging(true, 'right')"
      @touchstart.passive="setDragging(true, 'right')" />
  </div>
</template>

<style lang="scss" src="./style.scss" />
