import { NumberRegexp, OnFormatValueReplaceRegexp } from '../constants';
import type { SliderActionType, UseRangePickerOptions } from '../types';
import { currencyFormat, unCurrencyFormat } from '@/shared/utils/number';

export function useRangePicker(options: UseRangePickerOptions) {
  const { props, modelFrom, modelTo } = options;

  const isFocused = shallowRef(false);
  const isSliderDragged = shallowRef(false);
  const isSlidersSwitched = shallowRef(false);
  const valueFromOnDragged = shallowRef('');
  const valueToOnDragged = shallowRef('');
  const sliderFrom = ref(getSlideDiffValue(modelFrom.value));
  const sliderTo = ref(getSlideDiffValue(modelTo.value, 'to'));

  const valueFrom = computed({
    get() {
      return currencyFormat(modelFrom.value);
    },

    set(value: string) {
      modelFrom.value = unCurrencyFormat(value);
    },
  });
  const valueTo = computed({
    get() {
      return currencyFormat(modelTo.value);
    },

    set(value: string) {
      modelTo.value = unCurrencyFormat(value);
    },
  });
  const displayFromValue = computed(() => isSliderDragged.value ? valueFromOnDragged.value : valueFrom.value);
  const displayToValue = computed(() => isSliderDragged.value ? valueToOnDragged.value : valueTo.value);

  watch(() => modelFrom.value, (value) => {
    if (value) {
      const val = isSlidersSwitched.value ? modelTo.value : value;
      sliderFrom.value = getSlideDiffValue(val);
    }
  });

  watch(() => modelTo.value, (value) => {
    if (value) {
      const val = isSlidersSwitched.value ? modelFrom.value : value;
      sliderTo.value = getSlideDiffValue(val, 'to');
    }
  });

  function getOnlyIntSymbolsMatchFromString(value: string) {
    return unCurrencyFormat(value
      .replaceAll(OnFormatValueReplaceRegexp, '')
      .match(NumberRegexp)
      ?.toString() || '',
    );
  }

  function formatValueOnInput(e: Event) {
    if (e.target instanceof HTMLInputElement) {
      const value = getOnlyIntSymbolsMatchFromString(e.target.value);
      e.target.value = currencyFormat(value);
    }
  }

  function getSlideDiffValue(value: number | string, type: SliderActionType = 'from') {
    if (props.min === props.max) {
      return type === 'from' ? 0 : 100;
    }

    const full = props.max - props.min;
    const difference = +value - props.min;
    const result = Math.round((100 * difference) / full);
    return result <= 0 ? 0 : result;
  }

  function minMaxHandler(value: number, min: number, max: number): number {
    return value < min ? min : value > max ? max : value;
  }

  function getValueRange(from: number = 0, to: number = 0) {
    const result = {
      from: minMaxHandler(from, props.min, props.max),
      to: minMaxHandler(to, props.min, props.max),
    };

    if (result.from > result.to) {
      const fromValue = result.to;
      result.to = result.from;
      result.from = fromValue;
    }
    if (result.to < result.from) {
      const toValue = result.from;
      result.from = result.to;
      result.to = toValue;
    }

    return result;
  }

  function updateValueOnBlur(e: Event, type: SliderActionType) {
    setFocus(false);
    if (e.target instanceof HTMLInputElement) {
      const result = getOnlyIntSymbolsMatchFromString(e.target.value);

      if (result) {
        const isFrom = type === 'from';
        const fromModel = isFrom ? result : unCurrencyFormat(displayFromValue.value);
        const toModel = !isFrom ? result : unCurrencyFormat(displayToValue.value);

        const { from, to } = getValueRange(+fromModel, +toModel);
        valueFrom.value = from.toString();
        valueTo.value = to.toString();
        sliderFrom.value = getSlideDiffValue(from);
        sliderTo.value = getSlideDiffValue(to, 'to');

        e.target.value = isFrom ? displayFromValue.value : displayToValue.value;
      }
    }
  }

  function onSlideMoved(value: number, type: SliderActionType) {
    const handlers = {
      from: () => {
        const calcValue = Math.floor(((props.max - props.min) / 100) * value);

        if (value === 0) {
          valueFromOnDragged.value = currencyFormat(props.min);
        }

        if (calcValue >= props.max) {
          valueFromOnDragged.value = currencyFormat(props.max);
          return;
        }

        valueFromOnDragged.value = currencyFormat(props.min + calcValue);
      },

      to: () => {
        const calcValue = Math.floor((((props.max - props.min) / 100) * value));
        if (calcValue >= props.max) {
          valueToOnDragged.value = currencyFormat(props.max);
          return;
        }
        valueToOnDragged.value = currencyFormat(props.min + calcValue);
      },
    };

    let actionType = type;

    if (sliderTo.value < sliderFrom.value) {
      actionType = type === 'to' ? 'from' : 'to';
    }
    isSlidersSwitched.value = actionType !== type;
    return handlers[actionType]();
  }

  function setValuesOnSlideDragged() {
    valueFromOnDragged.value = valueFrom.value;
    valueToOnDragged.value = valueTo.value;
    isSliderDragged.value = true;
  }

  function updateValuesOnSlideDropped() {
    isSliderDragged.value = false;
    valueFrom.value = unCurrencyFormat(valueFromOnDragged.value);
    valueTo.value = unCurrencyFormat(valueToOnDragged.value);
  }

  function setFocus(value: boolean = true) {
    isFocused.value = value;
  }

  return {
    isFocused,
    sliderFrom,
    sliderTo,
    displayFromValue,
    displayToValue,
    setFocus,
    formatValueOnInput,
    setValuesOnSlideDragged,
    updateValuesOnSlideDropped,
    onSlideMoved,
    updateValueOnBlur,
  };
}
