import type { FiltersState } from '@/stores/filters/types';

export function useAnalyticsFilter(filters: FiltersState, initFilters?: FiltersState) {
  const { $analytics } = useNuxtApp();
  const configStore = useConfigStore();
  const { currencyLabel, squareLabel } = storeToRefs(configStore);

  const _prev: FiltersState = initFilters || filters;
  const prevFilters = ref(toValue({ ..._prev }));
  const lastConfig = reactive({
    currency: currencyLabel.value,
    square: squareLabel.value,
  });

  function trackFilterEvents(value: FiltersState, oldValue: FiltersState): void {
    if (value.priceFrom !== oldValue.priceFrom || value.priceTo !== oldValue.priceTo) {
      $analytics.trackEvent('apply_price_range', 'click', {
        price_range_from: value.priceFrom, // цена от
        price_range_to: value.priceTo, // цена до
        currency_value: currencyLabel.value,
      });
    }
    if (value.squareFrom !== oldValue.squareFrom || value.squareTo !== oldValue.squareTo) {
      $analytics.trackEvent('apply_area_range', 'click', {
        size_range_from: value.squareFrom, // размер от
        size_range_to: value.squareTo, // размер до,
        size_measure_value: squareLabel.value,
      });
    }
    if (value.handoverDates.length !== oldValue.handoverDates.length) {
      const handover_date_value = value.handoverDates.toString().replace(/hndt:|hnd:/g, '');
      $analytics.trackEvent('apply_handover_date_filter', 'click', {
        handover_date_value, // зачение выбранной даты
      });
    }
    if (value.unitTypes.length !== oldValue.unitTypes.length) {
      $analytics.trackEvent('apply_property_type', 'click', {
        property_type: value.unitTypes.toString(), // Значение выбранное в фильтре (пример: Villa | Apartment)
      });
    }
    if (value.bedrooms.length !== oldValue.bedrooms.length) {
      $analytics.trackEvent('apply_bedrooms', 'click', {
        bedrooms: value.bedrooms.toString(), // кол-во выбранных комнат
      });
    }
    if (value.bathrooms.length !== oldValue.bathrooms.length) {
      $analytics.trackEvent('apply_bathrooms', 'click', {
        bathrooms: value.bathrooms.toString(), // кол-во выбранных ванных комнат
      });
    }
    if (value.amenities.length !== oldValue.amenities.length) {
      $analytics.trackEvent('apply_amenities', 'click', {
        amenities: value.amenities.toString(), // значение amenities
      });
    }
  }

  function analyticResetFilter() {
    prevFilters.value = toValue({ ...filters });
    $analytics.trackEvent('reset_filters', 'click');
  }

  watch(filters, async (value) => {
    const price = value.priceFrom && value.priceTo;
    const square = value.squareFrom && value.squareTo;
    if (!price || !square) {
      return;
    }

    if (currencyLabel.value !== lastConfig.currency || squareLabel.value !== lastConfig.square) {
      lastConfig.currency = currencyLabel.value;
      lastConfig.square = squareLabel.value;
      return;
    }

    const notSameFromPriceSquare = (value.priceFrom !== prevFilters.value.priceFrom) && (value.squareFrom !== prevFilters.value.squareFrom);
    if (notSameFromPriceSquare) {
      return;
    }

    trackFilterEvents(value, prevFilters.value);

    await nextTick();
    prevFilters.value = JSON.parse(JSON.stringify(value));
  });

  return { analyticResetFilter };
}
